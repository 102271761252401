import { bus } from 'src/plugins/bus'
import { getError } from 'src/lib/error_message_helper'
import { getField, updateField } from 'vuex-map-fields'
import { messages } from 'src/lib/messages'
import { sanitizePhonenumber } from 'src/lib/sanitizer'
import { sleep } from 'src/lib/helpers'
import axios from 'src/lib/axios'
import router from 'src/router'
import userAgentParser from 'ua-parser-js'

const getDevice = () => {
  const parser = new userAgentParser()
  const result = parser.getResult()

  return `${result.browser.name} ${result.os.name} ${result.os.version}`
}

const DEFAULT = 'default',
  IOS = 'ios',
  ANDROID = 'android'
const getDefaultState = () => ({
  msisdn: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  loggedIn: false,
  deliveryType: undefined,
  rememberMe: false,
  errorCode: undefined,
  passwordResetToken: undefined,
  persistentAuthenticationStore: [],
  weakPassword: false,
  isLteCapable: true,
  hasLteSimCardProductionError: false,
  emailValidation: {
    obfuscatedMsisdn: undefined,
    customerNumber: undefined,
    msisdnToken: undefined,
    brandKey: undefined
  },
  emailValidationSmsCodeRequested: false,
  emailVerified: false,
  appmode: undefined
})

const state = getDefaultState()

const getters = {
  getField,
  getHeader: state => {
    return {
      'x-auth': state.accessToken
    }
  },
  getRememberMe: state => {
    return state.rememberMe
  },
  getMsisdn: state => {
    return state.msisdn
  },
  getToken: state => {
    return state.token
  },
  getPasswordResetToken: state => {
    return state.passwordResetToken
  },
  getPasswordResetDto: state => {
    return {
      msisdn: state.msisdn,
      deliveryType: state.deliveryType
    }
  },
  isLoggedIn: state => {
    return state.loggedIn && !!state.accessToken
  },
  getTrackingLoginStatus: state => {
    return state.loggedIn ? 'logged_in' : 'logged_out'
  },
  getAuthenticationStore: state => state.persistentAuthenticationStore,
  hasWeakPassword: state => state.weakPassword,
  getAccessToken: state => state.accessToken,
  getRefreshToken: state => state.refreshToken,
  showWeakPasswordWarning: state => {
    if (state.appmode) return false

    return state.weakPassword
  },
  getEmailValidation: state => state.emailValidation,
  getSmsCodeRequested: state => state.emailValidationSmsCodeRequested,
  getEmailVerified: state => state.emailVerified,
  getAppmode: state => state.appmode,
  getAppmodeQueryParam: state => {
    const query = { appmode: state.appmode }

    if (state.appmode === DEFAULT) {
      query.appmode = null
    }

    return query
  }
}

const mutations = {
  updateField,
  updateWeakPassword(state, isWeak) {
    state.weakPassword = isWeak
  },

  setMsisdn(state, msisdn) {
    state.msisdn = msisdn
  },

  sanitizeMsisdn(state) {
    state.msisdn = sanitizePhonenumber(state.msisdn)
  },

  loginSuccess(state, result) {
    state.accessToken = result.accessToken
    state.refreshToken = result.refreshToken
    state.loggedIn = true
    state.isLteCapable = result.lteCapable === false ? false : true
    state.hasLteSimCardProductionError = result.lteSimCardProductionError
  },

  updateAccessToken(state, result) {
    state.accessToken = result.accessToken
    state.refreshToken = result.refreshToken
  },

  error(state, errorCode) {
    state.loggedIn = false
    state.errorCode = errorCode
  },

  addToAuthenticationStore(state) {
    const updateElement = state.persistentAuthenticationStore[state.msisdn]

    if (updateElement) {
      updateElement.token = state.token
      updateElement.token = state.token
    } else {
      state.persistentAuthenticationStore[state.msisdn] = {
        msisdn: state.msisdn,
        token: state.token,
        alias: ''
      }
    }
  },

  updateAlias(state, { msisdn, alias }) {
    state.persistentAuthenticationStore[msisdn].alias = alias
  },
  setEmailValidation(state, result) {
    state.emailValidation = result
  },
  setEmailVerified(state, emailValidated) {
    state.emailVerified = emailValidated
  },
  setSmsCodeRequested(state) {
    state.emailValidationSmsCodeRequested = true
  },
  updateAppmode(state, appmode) {
    switch (appmode) {
      case undefined:
      case IOS:
      case ANDROID:
        state.appmode = appmode

        return
      default:
        state.appmode = DEFAULT

        return
    }
  },
  reset(state) {
    Object.assign(state, {
      ...getDefaultState(),
      persistentAuthenticationStore: state.persistentAuthenticationStore
    })
  }
}

const actions = {
  async login({ commit, dispatch, getters, rootGetters }, password) {
    commit('sanitizeMsisdn')
    try {
      const audience = getters['getAppmode'] ? 'web_view' : 'web'

      const payload = {
        msisdn: getters['getMsisdn'],
        password: password,
        audience: audience,
        device: getDevice()
      }

      const result = await axios.post(
        '/rest-api/v1/authentication/login',
        payload,
        {
          skipAuthRefresh: true
        }
      )

      commit('loginSuccess', result.data)
      commit(
        'selfcareHome/setEmailVerificationStatus',
        result.data.emailVerificationStatus,
        { root: true }
      )
      commit('updateWeakPassword', result.data.hasWeakPassword)

      if (getters['getRememberMe']) {
        commit('addToAuthenticationStore')
      }

      return true
    } catch (e) {
      dispatch('apiErrors/handleError', { ...e, silent: true }, { root: true })

      const { errorCode, redirectUrl, errorMessage } =
        rootGetters['apiErrors/getError']

      if (errorCode === 'login_with_wrong_brand' && redirectUrl) {
        bus.emit('info', errorMessage)
        await sleep(8000)
        window.location.assign(redirectUrl)
      } else {
        commit('error', errorCode)
        bus.emit('error', errorMessage)
      }

      return false
    }
  },

  async refreshAccessToken({ getters, commit }) {
    try {
      const result = await axios.post(
        '/rest-api/v1/authentication/refresh-session',
        {
          refreshToken: getters['getRefreshToken'],
          audience: 'web'
        },
        { skipAuthRefresh: true }
      )

      commit('updateAccessToken', {
        accessToken: result.data.accessToken,
        refreshToken: getters['getRefreshToken']
      })

      return true
    } catch (e) {
      return false
    }
  },

  async loginWithToken({ getters, commit }, { webAccessToken, audience }) {
    try {
      const result = await axios.post(
        '/rest-api/v1/authentication/web-session',
        {
          webAccessToken,
          audience: audience || 'web',
          device: getDevice()
        },
        { skipAuthRefresh: true }
      )

      commit('setMsisdn', result.data.msisdn)
      commit('loginSuccess', {
        accessToken: result.data.accessToken,
        refreshToken: result.data.refreshToken
      })

      if (getters['getRememberMe']) {
        commit('addToAuthenticationStore')
      }

      return true
    } catch (e) {
      commit('error', getError(e.response))

      return false
    }
  },

  async logout({ getters, dispatch }, redirectToHome = true) {
    try {
      await axios.post(
        '/rest-api/v1/authentication/logout',
        {
          refreshToken: getters['getRefreshToken']
        },
        {
          headers: {
            ...getters['getHeader']
          }
        }
      )
    } catch (e) {
      console.error(e)
    }

    dispatch('reset', null, { root: true })
    dispatch('settings/initTheme', null, { root: true })

    if (redirectToHome && router.currentRoute.name !== 'PortalHome') {
      router.push({ name: 'PortalHome' })
    }
  },
  async requestPasswordReset({ commit, getters, dispatch, rootGetters }) {
    commit('sanitizeMsisdn')
    const dto = getters['getPasswordResetDto']

    try {
      await axios.post('/rest-api/v1/customer/password/request-reset', dto)

      router.push({ name: 'Login' })
      bus.emit(
        'success',
        messages.requestPasswordReset.success[dto.deliveryType],
        true
      )
    } catch (e) {
      dispatch('apiErrors/handleError', e, { root: true })
      commit('error', rootGetters['apiErrors/getErrorCode'])
    }
  },

  async changePasswordWithToken(
    { commit, getters, rootGetters, dispatch },
    headers
  ) {
    commit('sanitizeMsisdn')
    try {
      await axios.post(
        '/rest-api/v1/customer/password/reset',
        {
          ...rootGetters['password/getDto'],
          msisdn: getters['getMsisdn'],
          token: getters['getPasswordResetToken']
        },
        { headers: headers }
      )
      router.push({ name: 'Login' })
      bus.emit(
        'success',
        'Ihr Passwort wurde gespeichert. Sie können sich nun mit dem neuen Passwort einloggen.',
        true
      )
    } catch (e) {
      dispatch('apiErrors/handleError', e, { root: true })
      commit('error', rootGetters['apiErrors/getErrorCode'])
    }
  },

  async validateEmailVerificationToken(
    { commit, dispatch, rootGetters },
    { token, brandKey }
  ) {
    try {
      const result = await axios.post(
        '/rest-api/v2/user-data/email/secure/validate',
        {
          token: token,
          brandKey
        },
        { headers: { 'Content-Type': 'application/json', accept: '*/*' } }
      )

      if (
        (!('obfuscatedMsisdn' in result.data) ||
          !('msisdnToken' in result.data)) &&
        !('customerNumber' in result.data)
      ) {
        bus.emit(
          'error',
          'Leider ist die Validierung des Tokens fehlgeschlagen. Es konnte keine Mobilfunk- oder Kundennummer zugeordnet werden. Bitte wenden Sie sich an die Kundenbetreuung unter der Telefonnummer <a href="tel:+4917688880000">0176 8888 0000</a>.',
          false
        )
      }

      commit('setEmailValidation', { ...result.data, brandKey })
    } catch (e) {
      commit('error', rootGetters['apiErrors/getErrorCode'])
      dispatch('apiErrors/handleError', e, { root: true })
    }
  },

  async verifyEmailByMsisdn(
    { commit, dispatch, getters, rootGetters },
    { password }
  ) {
    const payload = {
      brandKey: getters['getEmailValidation'].brandKey,
      msisdnToken: getters['getEmailValidation'].msisdnToken,
      password,
      device: getDevice()
    }

    try {
      const result = await axios.post(
        '/rest-api/v2/user-data/email/verify/secure/login/msisdn',
        payload,
        { headers: { accept: '*/*' } }
      )

      commit('updateAccessToken', {
        accessToken: result.data.accessToken,
        refreshToken: result.data.refreshToken
      })
      commit('loginSuccess', result.data)
      commit('setEmailVerified', true)
      commit('selfcareHome/setEmailVerificationStatus', 'verified', {
        root: true
      })
    } catch (e) {
      dispatch('apiErrors/handleError', e, { root: true })
      commit('error', rootGetters['apiErrors/getErrorCode'])
    }
  },

  async verifyEmailByCustomerNumber(
    { commit, dispatch, getters, rootGetters },
    { password }
  ) {
    const payload = {
      brandKey: getters['getEmailValidation'].brandKey,
      customerNumber: getters['getEmailValidation'].customerNumber,
      password
    }

    try {
      await axios.post(
        '/rest-api/v2/user-data/email/verify/login/customer-number',
        payload,
        { headers: { accept: '*/*' } }
      )
      commit('setEmailVerified', true)
      commit('selfcareHome/setEmailVerificationStatus', 'verified', {
        root: true
      })
    } catch (e) {
      dispatch('apiErrors/handleError', e, { root: true })
      commit('error', rootGetters['apiErrors/getErrorCode'])
    }
  },
  async requestEmailVerificationSmsCode({
    commit,
    dispatch,
    getters,
    rootGetters
  }) {
    try {
      await axios.post(
        '/rest-api/v2/user-data/email/secure/tan',
        {
          msisdnToken: getters['getEmailValidation'].msisdnToken,
          brandKey: getters['getEmailValidation'].brandKey
        },
        { headers: { 'Content-Type': 'application/json', accept: '*/*' } }
      )

      commit('setSmsCodeRequested')
    } catch (e) {
      dispatch('apiErrors/handleError', e, { root: true })
      commit('error', rootGetters['apiErrors/getErrorCode'])
    }
  },
  async verifyEmailBySmsCode(
    { commit, dispatch, getters, rootGetters },
    { smsCode }
  ) {
    const payload = {
      brandKey: getters['getEmailValidation'].brandKey,
      msisdnToken: getters['getEmailValidation'].msisdnToken,
      tan: smsCode
    }

    try {
      await axios.post(
        '/rest-api/v2/user-data/email/verify/secure/tan',
        payload,
        { headers: { accept: '*/*' } }
      )
      commit('setEmailVerified', true)
      commit('selfcareHome/setEmailVerificationStatus', 'verified', {
        root: true
      })
    } catch (e) {
      dispatch('apiErrors/handleError', e, { root: true })
      commit('error', rootGetters['apiErrors/getErrorCode'])
    }
  },
  async validateNewCustomer(
    { commit, dispatch, rootGetters },
    { token, brandKey }
  ) {
    try {
      const response = await axios.post(
        '/rest-api/v2/user-data/email/secure/validate/new-customer',
        { brandKey, token }
      )

      commit('setEmailValidation', {
        ...response.data,
        brandKey
      })
      if (response.data.status === 'verified') {
        commit('setEmailVerified', true)
        dispatch('verification/verificationRequestDetails', {}, { root: true })
      }
    } catch (e) {
      dispatch('apiErrors/handleError', e, { root: true })
      commit('error', rootGetters['apiErrors/getErrorCode'])
    }
  },
  async decryptMsisdn({ commit }, encryptedMsisdn) {
    try {
      const result = await axios.post('/rest-api/v1/authentication/msisdn', {
        payload: encryptedMsisdn
      })

      commit('setMsisdn', result.data.msisdn)
    } catch (e) {
      console.error(e.code)
    }
  }
}

export default {
  name: 'authentication',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  DEFAULT,
  IOS,
  ANDROID,
  getDefaultState
}
