<template>
  <div class="portal-home--wrapper">
    <div data-cy="tariff_picker" class="home-section">
      <Notice v-if="showNotice()" />
      <TariffPicker />
    </div>

    <div v-if="showReviews" class="home-section home-section--customer-reviews">
      <div class="home-section--heading">
        <h2 class="title">Zufriedene Kunden sind unsere Priorität</h2>
        <ImageComponent
          v-if="$breakpoint.fromTablet()"
          class="customer-review--badge"
          width="120"
          height="49"
          :src="getImageUrl()"
          alt="Trusted Shops Logo"
        />
      </div>
      <Reviews />
      <a
        class="customer-reviews-link"
        :href="`https://www.trustedshops.de/bewertung/info_${$brand.trustedShopsId}.html`"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon name="keyboard__arrow--right" height="27" up="3" />
        Alle TrustedShops-Bewertungen ansehen
      </a>
    </div>

    <div v-else class="home-section">
      <SelfcareRedirect />
    </div>

    <div class="home-section--fonic home-section">
      <h2 class="title">Ihre Prepaid Vorteile mit {{ $brand.name }}</h2>
      <p>
        Setzen Sie auf {{ $brand.name }}, um alle Prepaid Vorteile zu genießen.
        So bleiben Sie jederzeit flexibel: in Deutschland und bei
        <router-link :to="'/prepaid-tarife/prepaid-ausland'"
          >Prepaid im Ausland</router-link
        >
      </p>

      <div class="prepaid-advantages-table">
        <div class="prepaid-advantages-table__row">
          <Icon
            class="prepaid-advantages-table__img"
            name="kostenkontrolle"
            :height="iconSize"
          />

          <div class="prepaid-advantages-table__text">
            <h3 class="subtitle">Volle Kostenkontrolle</h3>

            <p>
              Mit {{ $brand.name }} haben Sie jederzeit die volle Kontrolle. Sie
              zahlen niemals mehr, als Sie zuvor auf Ihr Guthaben eingezahlt
              haben. Ihr verfügbares Guthaben fragen Sie im Handumdrehen über
              Handy,
              <router-link to="/login">Mein {{ $brand.name }}</router-link>
              oder die
              <router-link :to="`/service/${$brand.code}-app`"
                >{{ $brand.name }} App</router-link
              >
              ab. Genial einfach, auch um eine
              <router-link :to="`/prepaid-tarife/fuer-kinder`"
                >Prepaid Karte für Kinder</router-link
              >
              im Blick zu behalten.
            </p>
          </div>
        </div>
        <div class="prepaid-advantages-table__row">
          <Icon
            class="prepaid-advantages-table__img"
            name="flexibel"
            :height="iconSize"
          />

          <div class="prepaid-advantages-table__text">
            <h3 class="subtitle">Jederzeit flexibel</h3>

            <p>
              Mit {{ $brand.name }} gibt es keine Vertragsbindung. Sie bleiben
              also maximal flexibel. Bei Bedarf wechseln Sie einfach monatlich
              den <router-link to="/prepaid-tarife">Tarif</router-link>. Damit
              sind Sie mobil jederzeit bestens aufgestellt.
            </p>
          </div>
        </div>
        <div class="prepaid-advantages-table__row">
          <Icon
            class="prepaid-advantages-table__img"
            name="starkes-netz"
            :height="iconSize"
          />

          <div class="prepaid-advantages-table__text">
            <h3 class="subtitle">Starkes Netz</h3>

            <p>
              Mit der Prepaid SIM-Karte von {{ $brand.name }} nutzen Sie das
              leistungsfähige Mobilfunknetz von Telefónica fürs Telefonieren und
              für das
              <router-link to="/prepaid-tarife/mobiles-internet"
                >mobile Internet</router-link
              >
              mit bis zu 50 MBit/s. Und dank EU-Roaming im EU-Ausland sind Sie
              mit Ihrem
              <router-link :to="{ name: 'PrepaidHardwareLandingPage' }"
                >Prepaid Handy</router-link
              >
              auch auf Reisen gut unterwegs.
            </p>
          </div>
        </div>
      </div>
    </div>

    <Badges :reverse="$breakpoint.upToTablet()" class="home-section badges">
      <TopAppBadge class="filled" :heading-level="2" />

      <BenefitsBadge class="filled" :heading-level="2" />

      <AwardsBadge class="filled" :heading-level="2" />
    </Badges>
  </div>
</template>

<script>
import { DARK } from 'src/components/common/settings/themes.js'
import { mapState } from 'vuex'
import ImageComponent from 'src/components/common/image/image.vue'
import Notice from 'src/components/common/notice/notice.vue'
import TariffPicker from 'src/components/portal/common/tariff_picker_rework/tariff_picker.vue'

import { defineAsyncComponent } from 'vue'
import Icon from 'src/components/common/icon/icon.vue'
import Stage from '../../common/stages/${BRAND_CODE}/autumn.vue'

export default {
  name: 'PortalHome',
  components: {
    Badges: defineAsyncComponent(() =>
      import('src/components/portal/common/badges/badges.vue')
    ),
    AwardsBadge: defineAsyncComponent(() =>
      import('src/components/portal/common/badges/common/awards.vue')
    ),
    BenefitsBadge: defineAsyncComponent(() =>
      import('src/components/portal/common/badges/common/benefits.vue')
    ),
    TopAppBadge: defineAsyncComponent(() =>
      import('src/components/portal/common/badges/common/top_app.vue')
    ),
    Reviews: defineAsyncComponent(() =>
      import('src/components/portal/common/reviews/reviews.vue')
    ),
    TariffPicker,
    SelfcareRedirect: defineAsyncComponent(() =>
      import(
        'src/components/portal/common/selfcare_redirect/selfcare_redirect.vue'
      )
    ),
    Notice,
    ImageComponent,
    Icon
  },
  provide() {
    return {
      imageAlt: this.imageAlt
    }
  },
  inject: ['$http'],
  data() {
    return {
      showReviews: this.$brand.isFonic(),
      iconSize: '70',
      imageAlt: `${this.$brand.name}: unsere klassische Prepaid-Karte`
    }
  },
  computed: {
    ...mapState({
      theme: state => state.settings.theme
    }),
    isHighContrastModeActive() {
      return this.theme === DARK
    }
  },
  created() {
    this.$bus.emit('show-stage', Stage)
    this.$bus.on('trustedshops-load-error', this.onLoadError)
  },
  beforeUnmount() {
    this.$bus.off('trustedshops-load-error', this.onLoadError)
  },
  methods: {
    getImageUrl() {
      return new URL(
        `./resources/e-trustedshops${
          this.isHighContrastModeActive ? '-white' : ''
        }.svg`,
        import.meta.url
      ).href
    },
    onLoadError() {
      this.showReviews = false
    },
    showNotice() {
      return Date.now() < new Date('2023-02-25')
    }
  }
}
</script>

<style lang="scss" scoped>
.portal-home--wrapper {
  display: flex;
  flex-direction: column;
}

.portal-opportunities {
  > div {
    display: flex;

    @include breakpoint($up-to-tablet) {
      flex-direction: column;
    }
  }

  .portal--top-phone {
    margin: 8px;

    @include breakpoint($from-tablet) {
      margin: 8px 8px 8px 0;
    }
  }
}

.home-section {
  &:nth-child(even) {
    @include full-width-bg;

    padding-bottom: 50px;
    padding-top: 50px;

    .portal--top-phone {
      background-color: var(--background-primary);
    }

    .badge {
      border: inherit;
      background-color: var(--background-primary);
    }
  }

  &:nth-child(odd) {
    padding-bottom: 50px;
    padding-top: 50px;

    .badge {
      border: inherit;
      background-color: var(--background-secondary);
    }
  }

  // TODO: refactor after A/B-Test
  .home-section--first-element {
    margin-top: -40px;
  }

  &:last-child {
    margin-bottom: -40px;
  }
}

.home-section--fonic {
  padding-bottom: 40px;
}

.prepaid-advantages-table__row {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  @include breakpoint($from-tablet) {
    flex-direction: row;

    .prepaid-advantages-table__text {
      margin: 0 0 0 20px;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid color.adjust($root-color, $lightness: 60%);
  }
}

.prepaid-advantages-table__text {
  @include ie-only {
    width: 100%;
  }

  p {
    margin-bottom: 0;
  }
}

.home-section--heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.customer-review--badge {
  position: relative;

  :deep(img) {
    max-width: none;
  }
}

.home-section--customer-reviews {
  display: flex;
  flex-direction: column;
}

.customer-reviews-link {
  @include font-bold;

  color: var(--link-color);
  padding-right: 10px;
  margin-top: 25px;
  line-height: 1;
  margin-left: auto;

  @include breakpoint($up-to-desktop) {
    margin-left: 0;
    text-align: center;
  }

  @include breakpoint($up-to-tablet) {
    margin-top: 0;
  }
}

.prepaid-advantages-table__img {
  padding: 20px;
  max-width: 120px;
  min-width: 120px;

  @include breakpoint($up-to-tablet) {
    padding: 0 0 20px;
  }

  @include ie-only {
    :deep(img) {
      width: 100%;
    }
  }
}

h1.title {
  margin-top: 0;
}
</style>
