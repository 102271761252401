import { ACTIVATION_VZDOC, CHECKOUT_VZDOC } from '../step/steps'
import { bus } from 'src/plugins/bus'
import { formatDay, formatMonth, formatYear } from 'src/lib/date'
import { getField, updateField } from 'vuex-map-fields'
import axios from 'src/lib/axios'
import brand from '@brand'

const VZDOC_NOT_REQUIRED = 451

const getDefaultState = () => ({
  fetched: false,
  downloaded: false,
  disabled: false,
  id: null,
  url: undefined,
  mock: {
    password: '123456qwertz',
    accountId: 'DE70100000001122334455',
    bankId: 'UNKNOWN1111',
    mandateReference: null,
    address: {
      city: 'Unknown',
      country: 'Deutschland',
      street: 'Unknown',
      streetNumber: '1',
      supplement: '',
      telephone: '',
      zipCode: '11111'
    },
    mnp: null,
    personalData: {
      birthdate: '1.1.1991',
      email: 'example@eg.de',
      firstname: 'Unknown',
      lastname: 'Unknown',
      salutation: 'Herr'
    },
    topup: {
      amount: null,
      time: null,
      type: 'none'
    }
  }
})

const state = getDefaultState()

const breakpointIsUpToDesktop = () => {
  return ['tablet', 'mobile'].includes(
    window.getComputedStyle(document.body, ':before').content.replace(/"/g, '')
  )
}

const getters = {
  getField,
  getCheckoutMockDto: (state, getters, rootState, rootGetters) => {
    return {
      ...state.mock,
      tariffId: rootGetters['tariff/getTariffId'],
      advertisingPermissions: rootGetters['contactPermissions/getDto']
    }
  },
  getActivationMockDto: (state, getters, rootState, rootGetters) => {
    return {
      ...state.mock,
      registeredByAgent: false,
      ...rootGetters['activation/getLoginDto'],
      advertisingPermissions: rootGetters['contactPermissions/getDto']
    }
  },
  getVzDocId: state => {
    return state.id
  },
  getVzDocUrl: state => {
    return state.url
  },
  getVzDocFetched: state => {
    return state.fetched
  }
}

const actions = {
  async fetchVzDocumentActivation({ getters, commit, dispatch }) {
    if (getters['getVzDocFetched']) {
      return
    }

    try {
      const response = await axios.post(
        '/rest-api/v1/order/activation/document',
        getters['getActivationMockDto'],
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'application/pdf, application/json'
          }
        }
      )

      if (
        !(
          response &&
          response.data &&
          response.headers &&
          response.headers['document-id']
        )
      ) {
        bus.emit(
          'error',
          'Bei der Freischaltung ist ein Fehler aufgetreten. Das Dokument mit den Vertragsinformationen konnte nicht zur Vefügung gestellt werden. Bitte wenden Sie sich an die Kundenbetreuung unter der Telefonnummer <a href="tel:+4917688880000">0176 8888 0000</a>.'
        )
        commit('disableVzDocDownload')

        return
      }

      const blobObject = new Blob([response.data], {
        type: 'application/pdf'
      })

      commit('setVzDocUrl', URL.createObjectURL(blobObject))
      commit('setVzDocId', response.headers['document-id'])
      commit('vzDocFetched')
    } catch (e) {
      commit('disableVzDocDownload')
      if (e.response.status === VZDOC_NOT_REQUIRED) {
        commit('activation/setModeInvisible', ACTIVATION_VZDOC, { root: true })
        commit('vzDocDownloaded', true)
      } else {
        dispatch('apiErrors/handleError', e, { root: true })
      }
    }
  },
  async fetchVzDocumentCheckout({ getters, commit, dispatch }) {
    if (getters['getVzDocFetched']) {
      return
    }

    try {
      const response = await axios.post(
        '/rest-api/v1/order/checkout/document',
        getters['getCheckoutMockDto'],
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'application/pdf, application/json'
          }
        }
      )

      if (
        !(
          response &&
          response.data &&
          response.headers &&
          response.headers['document-id']
        )
      ) {
        bus.emit(
          'error',
          'Bei der Bestellung ist ein Fehler aufgetreten. Das Dokument mit den Vertragsinformationen konnte nicht zur Vefügung gestellt werden. Bitte wenden Sie sich an die Kundenbetreuung unter der Telefonnummer <a href="tel:+4917688880000">0176 8888 0000</a>.'
        )
        commit('disableVzDocDownload')

        return
      }

      const blobObject = new Blob([response.data], {
        type: 'application/pdf'
      })

      commit('setVzDocUrl', URL.createObjectURL(blobObject))
      commit('setVzDocId', response.headers['document-id'])
      commit('vzDocFetched')
    } catch (e) {
      commit('disableVzDocDownload')
      if (e.response.status === VZDOC_NOT_REQUIRED) {
        commit('checkout/setModeInvisible', CHECKOUT_VZDOC, { root: true })
        commit('vzDocDownloaded', true)
      } else {
        dispatch('apiErrors/handleError', e, { root: true })
      }
    }
  },
  downloadVzDocument({ getters, commit, rootGetters }) {
    const id = getters['getVzDocId']
    const url = getters['getVzDocUrl']
    const tarifname =
      rootGetters['tariff/getTitleOrAlternative'] || `${brand.name}_Tarif`
    const formattedName = tarifname.replace(/\s/g, '_')
    const today = new Date(Date.now())
    const formattedDate = `${formatYear(today)}-${formatMonth(
      today
    )}-${formatDay(today)}`
    const filename = `Vertragszusammenfassung_${formattedName}_${formattedDate}.pdf`

    if (!id) {
      console.error('Document ID missing...')

      commit('vzDocDownloaded', false)
    } else if (!url) {
      console.error('Document URL missing...')

      commit('vzDocDownloaded', false)
    } else {
      let link = document.createElement('a')

      link.setAttribute('id', 'checkoutVzDocDownloadLink')
      link.setAttribute('type', 'hidden')
      link.href = url
      link.download = filename

      document.body.appendChild(link)
      if (!window.Cypress) {
        // FF browser and Smartphone detection.
        // window.open is required since FF is no longer able to open download prompt for blob url.
        // Same for browsers on iOS.
        if (
          typeof InstallTrigger !== 'undefined' ||
          breakpointIsUpToDesktop()
        ) {
          window.open(link)
        } else link.click()
      }

      document.body.removeChild(link)

      // For Firefox it is necessary to delay revoking the ObjectURL
      setTimeout(function () {
        window.URL.revokeObjectURL(filename)
      }, 1000)

      commit('vzDocDownloaded', true)
    }
  }
}

const mutations = {
  updateField,
  disableVzDocDownload(state) {
    state.disabled = true
  },
  setVzDocId(state, id) {
    state.id = id || null
  },
  setVzDocUrl(state, url) {
    state.url = url || undefined
  },
  vzDocFetched(state) {
    state.fetched = true
  },
  vzDocDownloaded(state, status) {
    state.downloaded = status || false
  },
  reset(state) {
    Object.assign(state, getDefaultState())
  }
}

export default {
  name: 'vzdoc',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  getDefaultState
}
