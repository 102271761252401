const selfcarePath = '/selfcare'

export default [
  {
    path: selfcarePath,
    name: 'SelfcareHome',
    meta: { title: 'Selfcare', layout: 'selfcare' },
    component: () => import('src/components/selfcare/overview/home/home.vue')
  },
  {
    path: selfcarePath + '/tarife-und-optionen',
    name: 'SelfcareTariffOptions',
    meta: { title: 'Tarife und Optionen', layout: 'selfcare' },
    props: route => ({
      highlight: 'highlight' in route.query
    }),
    component: () =>
      import(
        'src/components/selfcare/overview/tariff_switch/tariff_overview.vue'
      )
  },
  {
    path: selfcarePath + '/tarif-option-aktivierung/:slug',
    name: 'SelfcareTariffActivateOption',
    meta: { title: 'Tarif Wechseln', layout: 'selfcare' },
    component: () =>
      import(
        'src/components/selfcare/overview/tariff_switch/activate_option/activate_option.vue'
      )
  },
  {
    path: selfcarePath + '/tarif-wechsel/:slug',
    name: 'SelfcareTariffSwitchTariff',
    meta: { title: 'Tarif Wechseln', layout: 'selfcare' },
    component: () =>
      import(
        'src/components/selfcare/overview/tariff_switch/switch_tariff/switch_tariff.vue'
      )
  },
  {
    path: selfcarePath + '/aufladen',
    name: 'SelfcareTopup',
    meta: { title: 'Guthaben aufladen', layout: 'selfcare' },
    component: () => import('src/components/selfcare/overview/topup/topup.vue')
  },
  {
    path: selfcarePath + '/datenverbrauch',
    name: 'SelfcareDataHistory',
    meta: { title: 'Datenverbrauch', layout: 'selfcare' },
    component: () =>
      import('src/components/selfcare/overview/data_history/data_history.vue')
  },
  {
    path: selfcarePath + '/gespraechsuebersicht',
    name: 'SelfcareUsage',
    meta: { title: 'Rechnung & Belege', layout: 'selfcare' },
    component: () => import('src/components/selfcare/overview/usage/usage.vue')
  },
  {
    path: selfcarePath + '/sim-freischalten',
    name: 'SelfcareWebshopSimActivation',
    meta: { title: 'Freischalten', layout: 'selfcare' },
    component: () =>
      import(
        'src/components/selfcare/overview/activation/webshop_sim_activation.vue'
      )
  },
  {
    path: selfcarePath + '/ersatz-sim-freischalten',
    name: 'SelfcareReplacementSimActivation',
    meta: { title: 'Freischalten', layout: 'selfcare' },
    component: () =>
      import(
        'src/components/selfcare/overview/activation/replacement_sim_activation.vue'
      )
  },
  {
    path: selfcarePath + '/gewinnspiel',
    name: 'SelfcareSpinWheel',
    meta: { title: 'Glücksrad', layout: 'selfcare' },
    component: () =>
      import('src/components/selfcare/overview/spinwheel/spinwheel.vue')
  }
]
