/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */
import { createApp, h } from 'vue'
import { plugIn as brandPlugIn } from 'src/lib/brand'
import { breakpointPlugIn } from 'src/plugins/breakpoints.js'
import { trackingPlugIn } from 'src/plugins/tracking.js'
import vueAnnouncer from '@vue-a11y/announcer'
import '@vue-a11y/announcer/dist/style.css'

import { busPlugin } from 'src/plugins/bus'
import { structuredDataPlugIn } from 'src/plugins/structured_data.js'
import VueCookies from 'vue3-cookies'
import { VueHeadMixin, createHead } from '@unhead/vue'

import 'src/components/common/a_b_test/setup.js'

import axios from 'src/lib/axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

import AppComponent from 'src/app.vue'
import router from 'src/router'
import store from 'src/store'
import mountBodyDirective from 'src/directives/mount_body'

import * as Sentry from '@sentry/vue'

export const app = createApp({
  setup() {
    return () => h(AppComponent)
  },
  data() {
    return {
      loading: true,
      intermediateLoading: Date.now() - window.firstRenderTimestamp > 1500,
      loadingAnimationElement: document.getElementById(
        'preload_loading_animation'
      ),
      outdatedBrowserWarning: document.getElementById(
        'outdated-browser-warning'
      )
    }
  },
  mounted() {
    document.dispatchEvent(new Event('vue-app-mounted'))
  },
  beforeCreate() {
    this.$router.beforeEach((to, from, next) => {
      if (this.intermediateLoading) {
        this.loading = true
        this.loadingAnimationElement.className = 'preload__container'
      }

      next()
    })

    this.$router.afterEach(() => {
      if (this.loading) {
        this.loading = false
        this.loadingAnimationElement.className =
          'preload__container preload__container--hidden'
        this.outdatedBrowserWarning.className =
          'outdated-browser-warning outdated-browser-warning--hidden'
      }
    })
  }
})

const isTesting = window.Cypress
const isPrerendering = window.navigator.userAgent.includes('prerenderer')

if (!isTesting && !isPrerendering) {
  const sentryRelease = import.meta.env.VITE_SENTRY_RELEASE_PREFIX
    ? import.meta.env.VITE_SENTRY_RELEASE_PREFIX +
      '-' +
      import.meta.env.VITE_BRAND_CODE
    : 'development'

  Sentry.init({
    app,
    dsn: 'https://38cf201186774063918a253e28caadce@sentry.fonic.de/2',
    release: sentryRelease,
    integrations: [Sentry.browserTracingIntegration({ router })],
    tracesSampleRate: 0.05,
    tracePropagationTargets: [
      /^http(s?):\/\/www\.fonic\.de/,
      /^http(s?):\/\/www\.fonic-mobile\.de/,
      /^\//
    ]
  })
}

app.use(store)
app.use(router)
app.use(brandPlugIn)
app.use(breakpointPlugIn)
app.use(trackingPlugIn, { router, store })
app.use(busPlugin)
app.use(structuredDataPlugIn, { router })
app.use(vueAnnouncer)
app.use(VueCookies, {
  expireTimes: '30d',
  path: '/',
  domain: '',
  secure: true,
  sameSite: 'None'
})
app.use(mountBodyDirective)

const head = createHead()

app.mixin(VueHeadMixin)
app.use(head)

const refreshAccessToken = async failedRequest => {
  if (await store.dispatch('authentication/refreshAccessToken')) {
    failedRequest.response.config.headers = {
      ...failedRequest.response.config.headers,
      ...store.getters['authentication/getHeader']
    }
  } else {
    const query = {
      redirect: window.location.pathname,
      reason: 'loginRequired'
    }

    router.push({
      name: 'Login',
      query: query
    })
  }

  return Promise.resolve()
}

createAuthRefreshInterceptor(axios, refreshAccessToken)

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 418) {
      app.config.globalProperties.$bus.emit('open-migrated-notice')
    } else if (error.response.status === 423) {
      //add blocked MSISDN handling
    }

    return Promise.reject(error)
  }
)

app.provide('$http', axios)

const setRouteAnnouncement = pagetitle => {
  app.config.globalProperties.$announcer.set(
    `Aktuelle Seite: ${pagetitle}`,
    'assertive'
  )
}

app.config.globalProperties.$bus.on('title', t => {
  setRouteAnnouncement(t)
})

router.beforeEach(async (to, from, next) => {
  if (to.name !== from.name) {
    app.config.globalProperties.$bus.emit('page-change')
  }

  if (to.meta && to.meta.title) {
    setRouteAnnouncement(to.meta.title)
  }

  if (Object.keys(to.query).includes('appmode')) {
    store.commit('authentication/updateAppmode', to.query.appmode)
  }

  const appmode = store.getters['authentication/getAppmode']

  // login via token
  if (
    to.query.token &&
    to.query.token.length > 5 &&
    !(to.meta.disableAutoLogin === true)
  ) {
    window.sessionStorage.setItem('lastInteraction', Date.now())

    let loginSuccessfull = await store.dispatch(
      'authentication/loginWithToken',
      {
        webAccessToken: to.query.token,
        audience: appmode ? 'app' : 'web'
      }
    )

    if (loginSuccessfull) {
      delete to.query.token
      delete to.query.msisdn

      next({ ...to, replace: true })
    } else {
      let query = {
        redirect: to.fullPath,
        reason: 'loginRequired',
        ...store.getters['authentication/getAppmodeQueryParam']
      }

      return next({
        name: 'Login',
        query: query
      })
    }
  }

  // check login status for selfcare
  if (to.path.includes('/selfcare') && !store.state.authentication.loggedIn) {
    if (to.query.msisdn) {
      // prefill msisdn if in query parameter
      store.commit('authentication/setMsisdn', to.query.msisdn)
    }

    let query = {
      redirect: to.fullPath,
      reason: 'loginRequired',
      ...store.getters['authentication/getAppmodeQueryParam']
    }

    return next({
      name: 'Login',
      query: query
    })
  }

  return next()
})

app.mount('#app')
